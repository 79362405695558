/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import contactImage from '../../assets/KOTHI/KOTHIGARDEN.png';
import NavigationBar from '../../Component/NavigationBar/NavigationBar'; // Ensure the image path is correct
import Footer from '../../Component/Footer/Footer';
import imagelogo from '../../assets/KOTHI/TheArtKothi.png'

const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
  },
  imageContainer: {
    margin: '20px auto',
    width: '80%',
    maxWidth: '600px',
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
  },
  contactDetails: {
    margin: '20px auto',
    fontSize: '18px',
    textAlign: 'center', // Center text and links in this section
  },
  contactDetailsLink: {
    color: '#B67861',
    textDecoration: 'none',
  },
  iconSection: {
    margin: '20px 0',
    textAlign: 'center', // Center icons in this section
  },
  icon: {
    width: '40px',
    height: '40px',
    margin: '0 10px',
    verticalAlign: 'middle',
  },
  mapContainer: {
    marginTop: '40px',
    textAlign: 'center', // Center the map container
  },
  iframe: {
    border: 'none',
    borderRadius: '8px',
    width: '100%',
    maxWidth: '800px',
  },
};

function Contact() {
  return (
    <div>   
      <NavigationBar/>
    <div style={styles.container}>
   
      
      {/* Image Container */}
      <div className="image-sectionHERO">
      <a href="/">
      <img
        src={imagelogo}
        alt="Centered Image" 
        className="KOTHILOGO"
      />
      </a>
         <h1>Contact</h1>
      <div style={styles.imageContainer}>
        <img
          src={contactImage}
          alt="Contact"
          style={styles.image}
        />
      </div>
      
      {/* Contact Details */}
      <div style={styles.contactDetails}>
        <p style={styles.contactDetails}>
          For reservations, <br></br>please email us at :  
          <a href="mailto:booking@theartkothi.com" style={styles.contactDetailsLink}>
           booking@theartkothi.com
          </a>
        </p>
        <p style={styles.contactDetails}>
        Contact number:- +919887170770, +918860072750
        </p>
        <p style={styles.contactDetails}>
        Address: 1509, Galleria Boulevard, Sector 28, DLF Phase IV, Gurugram, Haryana 122002
        </p>
      </div>
      
   
    
      
      {/* Map Section */}
      <section style={styles.mapContainer}>
      <div style={styles.mapContainer}>
        
        </div>

      </section>
    </div>
    <Footer/>
    </div>
    </div>
  );
}

export default Contact;
