import React from "react";
import "./CafeeKothi.css"; // Import the CSS file for styling
import logo from "../../assets/KOTHI/TheArtKothi.png"; // Adjust with your actual image path
import menuItems from  './menuItems';
import NavigationBar from "../../Component/NavigationBar/NavigationBar";
import Footer from "../../Component/Footer/Footer";

const MenuComponent = () => {
  return (
    <div>
      <NavigationBar/>
    <div>   
        <img src={logo} alt="Restaurant Logo" className="menu-logo" />
        <h1 className="menu-title">Art Cafe</h1>
        <br/>
   
    <div className="menu-container">
  
      <div className="menu-header">
      
     
      </div>

      <div className="menu-content">
        {menuItems.map((category, index) => (
          <div className="menu-category" key={index}>
            <h2 className="menu-category-title">{category.category}</h2>
            <ul className="menu-items-list">
              {category.items.map((item, itemIndex) => (
                <li className="menu-item" key={itemIndex}>
                  <span className="menu-item-name">{item.name}</span>
                  <span className="menu-item-price">{item.price}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default MenuComponent;
