/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react'; // Importing React and useState hook
import NavigationBar from '../../Component/NavigationBar/NavigationBar'; // NavigationBar component import
import './RoomSuit.css'; // Import the CSS file

// Importing relevant icons for the features section
import {
  FaLock,
  FaUtensils,
  FaWifi,
  FaLaptop,
  FaParking,
  FaPaw,
  FaTv,
  FaSnowflake,
  FaBell,
  FaFireExtinguisher,
} from 'react-icons/fa';

// Importing Footer component and images
import Footer from '../../Component/Footer/Footer';
import image1 from '../../assets/KOTHI/Kothi11.jpeg';
import image2 from '../../assets/KOTHI/Kothi12.jpeg';
import image3 from '../../assets/KOTHI/Kothi13.jpeg';
import image4 from '../../assets/KOTHI/Kothi23.jpeg';
import image5 from '../../assets/KOTHI/Kothi22.jpeg';
import image6 from '../../assets/KOTHI/Kothi29.jpeg';
import imagelogo from '../../assets/KOTHI/TheArtKothi.png';

// Importing images for name list
import nameImage1 from '../../assets/RoomImg/chouki.png';
import nameImage2 from '../../assets/RoomImg/naal.png';
import nameImage3 from '../../assets/RoomImg/shaal.png';
import nameImage4 from '../../assets/RoomImg/devalkhano.png';
import nameImage5 from '../../assets/RoomImg/चॊक.png';
import nameImage6 from '../../assets/RoomImg/kothi.png';
import nameImage7 from '../../assets/RoomImg/kothari.png';
import nameImage8 from '../../assets/RoomImg/oro.png';
import nameImage9 from '../../assets/RoomImg/padpito.png';
import nameImage10 from '../../assets/RoomImg/dillon.png';
import nameImage11 from '../../assets/RoomImg/daaglo.png';
import nameImage12 from '../../assets/RoomImg/maaliyo.png';
import nameImage13 from '../../assets/RoomImg/medhi.png';
import nameImage14 from '../../assets/RoomImg/tipad.png';
import nameImage15 from '../../assets/RoomImg/kothariyo.png';
import nameImage16 from '../../assets/RoomImg/gunjar.png';
import nameImage17 from '../../assets/RoomImg/bhkhari.png';
import nameImage18 from '../../assets/RoomImg/tilbhavro.png';
import nameImage19 from '../../assets/RoomImg/Palindo.png';
import nameImage20 from '../../assets/RoomImg/taarth.png';
import nameImage21 from '../../assets/RoomImg/nawanghar.png';
import nameImage22 from '../../assets/RoomImg/bhado.png';
// Add the remaining images...

function RoomSuit() {
  // useState hook to manage popup content
  const [popupContent, setPopupContent] = useState(null);

  // Function to handle click on a name item and open the popup
  const handleClick = (name, description) => {
    setPopupContent({ name, description });
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setPopupContent(null);
  };

  return (
    <div>
      {/* Navigation Bar */}
      <NavigationBar />

      {/* Room Suit Container */}
      <div className="room-suit-container">
        {/* Logo Image */}
        <a href="/">
          <img src={imagelogo} alt="Centered Image" className="KOTHILOGO" />
        </a>

        {/* Title and Paragraph for Room & Suite Section */}
        <h1 className="RoomText">Rooms & Suite</h1>
        <p className="ROOMTEXTPARA">
          At TheArtKothi, every corner is designed to enhance your stay, from the stunning views outside to the thoughtfully crafted interiors within.
        </p>

        {/* Image Grid Section */}
        <div className="image-grid">
          <img src={image1} alt="Image 1" />
          <img src={image2} alt="Image 2" />
          <img src={image3} alt="Image 3" />
          <img src={image4} alt="Image 4" />
          <img src={image5} alt="Image 5" />
          <img src={image6} alt="Image 6" />
        </div>

        {/* Name List Section */}
        <h1 className="RoomText">Various Parts of a House</h1>
        <div className="name-list">
          <div className="name-column">
            <div className="name-item" onClick={() => handleClick('चोकी (chauki)', 'Description about चोकी  The Chauki is much more than just a seating area; it is a symbol of hospitality, comfort, and tradition in Marwari homes. This elevated platform, typically constructed from stone or cement, is situated at the entrance of the house, often shaded by a tree or a simple canopy, offering a cool, peaceful place for residents and visitors alike. The height, usually 2-3 feet, serves not only as a practical solution to keep the seating area clean from dust and debris but also as a subtle elevation that sets it apart from the rest of the surroundings.Traditionally, the Chauki was a place where elders sat to watch over the comings and goings of the household, catching up on local news or simply observing the rhythm of daily life. It also became the go-to spot for travelers or neighbors passing by, where they could stop for a moment, exchange greetings, or chat with the family members who sat there during the early mornings or late evenings. In rural settings, the Chauki serves as a community gathering point, where decisions affecting the local area are sometimes discussed in an informal, congenial atmosphere.' )}>
              <img src={nameImage1} alt="चोकी (chauki)" />
              <p>चोकी (chauki)</p>
            </div>
            <div className="name-item" onClick={() => handleClick('नाल (naal)', 'Description about नाल The Naal, or staircase, serves as an architectural backbone, connecting different levels of the home both physically and symbolically. Unlike modern staircases that often prioritize form over function, the Naal in traditional Marwari homes is crafted with careful attention to both aesthetics and practicality. Often made from sturdy materials such as stone, these stairs are not merely a means of getting from one floor to another, but an integral part of the home’s design, with each step reflecting the cultural heritage and craftsmanship of the region.In a traditional setting, the Naal is not just a pathway; it plays a significant role in the daily lives of the family. Moving from one floor to another, residents carry out their everyday routines, be it fetching water, moving food from the kitchen to the terrace, or interacting with different family members. The sound of footsteps on the stone Naal evokes the timeless rhythms of domestic life, from the light footsteps of children running up to play on the terrace to the slow, deliberate steps of elders making their way down to the courtyard.')}>
              <img src={nameImage2} alt="नाल (naal)" />
              <p>नाल (naal)</p>
            </div>
            <div className="name-item" onClick={() => handleClick('बरहाली (barhali)', 'Description about बरहाली :- बरहाली (Barhali)The Barhali is more than just an open area in front of the rooms—it is the heart of family life, a place where connections are forged and traditions are maintained. This communal space, located at the center of the home’s activities, is where family members come together to relax, engage in conversations, and spend time with one another. Its openness allows for an easy flow of people and energy, making it a lively, dynamic area that adapts to the needs of the family.In traditional Marwari homes, the Barhali is where daily life unfolds, from children playing and running about, to elders enjoying their morning tea. The area is often spacious and well-lit, with a natural openness that encourages people to linger and interact. It may feature comfortable seating arrangements like low chairs or cushioned floor seating, allowing for both casual and extended gatherings. During cooler evenings, the Barhali becomes the perfect spot to unwind, with family members gathering to share stories, enjoy tea, or simply take in the peaceful ambiance.The Barhali often acts as a bridge between the private rooms of the house and the more public areas, serving as a transitional space where family members can easily access different parts of the home while still remaining connected to one another. The openness of the Barhali makes it ideal for hosting family celebrations, small gatherings, or even cultural rituals that are deeply rooted in tradition. In the evenings, the space takes on a magical quality as the sun sets, casting a soft glow over the area, making it perfect for quiet contemplation or communal dinners under the stars.')}>
              <img src={nameImage3} alt="बरहाली (barhali)" />
              <p>बरहाली (barhali)</p>
            </div>
            <div className="name-item" onClick={() => handleClick('देवलखनो (devalkhano)', 'Description about देवलखनो :- The Devalkhano stands as one of the most important spaces in a traditional Marwari home, functioning as the drawing room where guests are welcomed and entertained. Historically regarded as a formal and prestigious area, the Devalkhano is often the most aesthetically refined part of the house, serving as both a social hub and a display of the family’s hospitality, culture, and status. Every element in this room, from the furniture to the décor, is carefully chosen to reflect the family tastes, cultural values, and heritage.In traditional homes, the Devalkhano is typically located near the entrance of the house, ensuring that guests are greeted in a comfortable and elegant setting without having to venture too far into the private living areas. This room is where important visitors are received, be they friends, relatives, or community members, making it a space that exudes sophistication and grace. The layout and furnishings of the Devalkhano are thoughtfully arranged to facilitate conversation and interaction, with comfortable seating like plush sofas, ornate chairs, and decorative cushions strategically placed to encourage a warm, welcoming environment.Design-wise, the Devalkhano often features elements of luxury and tradition. The walls may be adorned with intricate artwork, tapestries, or family portraits, each telling a story of the household’s history and values. ')}>
              <img src={nameImage4} alt="देवलखनो (devalkhano)" />
              <p>देवलखनो (devalkhano)</p>
            </div>
            <div className="name-item" onClick={() => handleClick('चॊक (chok)', 'Description about चॊक  (chok):- The Chok is a defining feature of traditional Marwari homes, functioning as an inner courtyard at the heart of the house. This open-air space, often without a roof, serves as a natural centerpiece, connecting different rooms while bringing in an abundance of sunlight and fresh air. The Chok creates a harmonious balance between nature and the built environment, seamlessly integrating the outdoors into the daily life of the household. In many ways, the Chok is the pulse of the home, providing a sense of openness and freedom that enhances both the physical and emotional atmosphere of the house.One of the primary purposes of the Chok is to facilitate natural ventilation, especially important in hot and arid regions. The open design allows cool breezes to flow through the surrounding rooms, creating a natural air conditioning system that keeps the house comfortable throughout the day. In addition to cooling the house, the Chok helps circulate fresh air, ensuring that even the innermost parts of the home remain well-ventilated. This architectural feature not only improves the living conditions but also reflects the sustainable, climate-conscious designs of traditional homes.The Chok is more than just a functional space; it is also a social and cultural hub. In many homes, the Chok is where family members gather for informal conversations, celebrations, or rituals. The open sky above makes it an ideal spot for early morning prayers or evening gatherings under the stars.')}>
              <img src={nameImage5} alt="चॊक (chok)" />
              <p>चॊक (chok)</p>
            </div>
          </div>

          <div className="name-column">
            <div className="name-item" onClick={() => handleClick('कोठी (kothi)', 'Description about कोठी (kothi) :- The Kothi is one of the most versatile rooms in a traditional Marwari home, designed to meet the varying needs of the household. Its function can evolve over time, serving multiple purposes based on the family’s requirements. Unlike spaces that are dedicated to specific activities, the Kothi embodies flexibility, making it a vital part of the home’s design and functionality.Traditionally, the Kothi could be used as a private bedroom, offering a quiet retreat for relaxation and rest. When serving as a bedroom, the Kothi is often furnished with simple yet elegant furniture, including a bed, a wardrobe, and perhaps a small desk. In rural homes, the Kothi might also have traditional wooden chests or almirahs that store personal belongings, family heirlooms, or seasonal clothes. This room often reflects the personality of the occupant, with individual touches like decorative wall hangings, family photos, or religious icons adding warmth and character to the space.In some homes, the Kothi serves as a storage room, especially for larger families where space is at a premium. Here, the Kothi plays a practical role, providing ample room for storing everything from household essentials to agricultural tools, depending on the household lifestyle. Large containers filled with grains, jars of pickles, or extra kitchenware may find their place in the Kothi.')}>
              <img src={nameImage6} alt="कोठी (kothi)" />
              <p>कोठी (kothi)</p>
            </div>
            <div className="name-item" onClick={() => handleClick('कोठरी (kothari)', 'Description about कोठरी ( Kothri) :- The Kothri is a smaller, more intimate version of the Kothi, playing a crucial role in traditional Marwari homes. Its compact size makes it incredibly versatile, and while it may be modest in dimensions, its function is far from insignificant. The Kothri can be likened to a hidden treasure within the home—a space that offers both practicality and a quiet sanctuary for solitude.Primarily used for storage, the Kothri serves as the perfect solution for organizing and keeping personal belongings that need to be tucked away yet remain easily accessible. It is often the go-to space for storing household essentials like extra linens, blankets, kitchen supplies, or seasonal clothing. Many families use the Kothri to store valuable or fragile items that need to be kept out of sight, such as heirlooms, jewelry, or important documents. The room’s smaller, enclosed nature provides a sense of security, making it an ideal spot for storing valuables.Despite its practical use for storage, the Kothri can also function as a quiet, private retreat. Its compactness gives it a cozy, enclosed feeling that offers a sense of comfort and seclusion. In larger households where multiple family members live under one roof, the Kothri often becomes a quiet nook where someone can escape the hustle and bustle of daily life. Whether it used as a space for reading, reflection, or even meditation, the Kothri provides a much-needed respite within the lively environment of a traditional family home.')}>
              <img src={nameImage7} alt="कोठरी" />
              <p>कोठरी</p>
            </div>
            <div className="name-item" onClick={() => handleClick('ओरो (oro)', 'Description about ओरो (oro) :- The Oro is a distinctive architectural feature in traditional Marwari homes, offering a unique blend of privacy and functionality. Unlike typical rooms that open into common spaces or hallways, the Oro is a room that can only be accessed through another room. This design creates a layered structure within the house, adding depth and intimacy to the living space. The Oro functions as an extension of the primary room, often enhancing its usability and creating a more private, secluded environment.The Oro is highly valued for its ability to offer additional privacy within the home. In large, multi-generational families where communal living is common, having a private, enclosed space like the Oro becomes essential. Because it can only be reached by passing through another room, the Oro provides an extra layer of seclusion, making it ideal for use as a personal retreat, a study area, or even a prayer room. Its tucked-away location means it is less likely to be disturbed by the comings and goings of other family members, offering a quiet and peaceful space within the home.In many homes, the Oro serves as an extension of the main bedroom or living area, enhancing the functionality of the house. It may be used as a secondary bedroom, particularly for children or elderly members of the family who prefer the closeness of being near others while still maintaining some degree of privacy. ')}>
              <img src={nameImage8} alt="ओरो (oro)" />
              <p>ओरो</p>
            </div>
            <div className="name-item" onClick={() => handleClick('पडपीतो (padpeeto)', 'Description about पडपीतो (padpeeto) :- The Padpeeto is one of the most secluded and secure spaces in traditional Marwari homes, located deep within the layout of the house. It is an innermost chamber, accessible only through an Oro, further enhancing its sense of privacy and exclusivity. The design of the Padpeeto, characterized by the absence of windows and having only one entrance, ensures that it remains hidden from view and difficult to access. This makes the Padpeeto the perfect space for storing valuable items, important household possessions, and even heirlooms passed down through generations.In traditional homes, the Padpeeto is seen as the ultimate safe room. Its lack of windows prevents outsiders from peering in, while the single, secure entrance makes unauthorized access nearly impossible. This strategic design ensures maximum security for the family’s most prized possessions, whether it be jewelry, important documents, or valuable resources like grain or currency. The location of the Padpeeto within the Oro adds an additional layer of protection, as anyone trying to reach it would first have to pass through the Oro, which itself is often a private or semi-private space.')}>
              <img src={nameImage9} alt="पडपीतो (padpeeto)" />
              <p>पडपीतो (padpeeto)</p>
            </div>
            <div className="name-item" onClick={() => handleClick('डिलोन (dillon)', 'Description about डिलोन :- The Dillon is a spacious, multifunctional hall within traditional Marwari homes, commonly associated with comfort, rest, and ease, especially for the elderly members of the family. Its large, open layout makes it a central space in the house, often located in a convenient part of the home that allows easy access to other key areas, such as the kitchen, courtyard, or main entrance. The Dillon is more than just a room; it’s a place that embodies relaxation, family bonding, and a sense of communal living.The defining feature of the Dillon is its ample space, which is often furnished with a large bed, sometimes even multiple beds, allowing it to serve as a comfortable resting place for elderly family members. The room is designed with ease of movement in mind, ensuring that it is accessible and comfortable for those who may have mobility issues. The spaciousness of the Dillon is not only practical for the elderly, who may need room for caregivers or family members to assist them, but it also allows for family members to visit and spend time together in this shared space.')}>
              <img src={nameImage10} alt="डिलोन (dillon)" />
              <p>डिलोन (dillon)</p>
            </div>
          </div>

          <div className="name-column">
            <div className="name-item" onClick={() => handleClick('डागलो (daaglo)', 'Description about डागलो :- The Daglo is a quintessential feature of traditional homes, particularly in regions where open, outdoor spaces hold special importance. As the first terrace of the house, the Daglo serves as a transitional space between the indoor and outdoor environments, offering residents a place to unwind, connect with nature, and take in the surrounding views. Often raised above the main living area, the Daglo provides a unique vantage point from which to enjoy both the peace of the home and the vibrancy of the world outside.One of the primary functions of the Daglo is to act as a social and leisure space for the family. Whether its used for early morning exercises, afternoon lounging, or evening gatherings, the Daglo offers an open, airy environment that is perfect for relaxing and spending quality time with loved ones. Families often take advantage of the Daglos spaciousness to engage in a variety of activities—children playing, adults enjoying tea, or elders basking in the warmth of the sun. The open layout of the terrace allows family members to spread out and enjoy their leisure time while still being connected to the house.')}>
              <img src={nameImage11} alt="डागलो (daaglo)" />
              <p>डागलो (daaglo)</p>
            </div>
            <div className="name-item" onClick={() => handleClick('मालियो (maaliyo)', 'Description about मालियो :- The Maaliyo is a unique architectural feature found in traditional Marwari homes, situated on the first terrace, offering a versatile space that serves multiple purposes depending on the family’s needs. As a room that is closely connected to the outdoor terrace (Daglo), the Maaliyo provides both functionality and flexibility, making it an essential part of the household. Whether it is used as a secondary living space, for storage, or for other practical purposes, the Maaliyo is a vital extension of the home’s indoor and outdoor living areas.One of the primary functions of the Maaliyo is as a secondary living space. Given its proximity to the terrace, the Maaliyo offers a semi-private, relaxed environment that is perfect for informal gatherings, afternoon naps, or even as a retreat for family members seeking a quiet space. This room often serves as an additional family room, where one can enjoy the natural light and fresh air that flows in from the terrace, creating a tranquil atmosphere that is ideal for rest and relaxation. In many homes, the Maaliyo is furnished with comfortable seating, cushions, and perhaps even a low bed or charpai, making it a welcoming place to unwind.')}>
              <img src={nameImage12} alt="मालियो (maaliyo)" />
              <p>मालियो (maaliyo)</p>
            </div>
            <div className="name-item" onClick={() => handleClick('मेढी (medhi)', 'Description about मेढी :- The Medhi is a compact but highly functional space located at the top of the stairs leading to the terrace in traditional homes, particularly in Marwari households. Although small, this room plays a crucial role in organizing and managing household items, making it an essential part of the home’s architecture. Its strategic placement at the stairway to the terrace ensures that it is both accessible and practical, often serving as a go-to storage area for items that are frequently used or need to be kept close but out of sight.One of the primary uses of the Medhi is to store household items like extra bedding, quilts, pillows, or blankets. These items are usually bulky and require storage during warmer months when they are not needed, and the Medhi provides a convenient space to tuck them away until they are required again. Its compact size makes it ideal for keeping such seasonal items organized, ensuring that they are easily accessible when needed, especially during times of change in weather or when accommodating guests.')}>
              <img src={nameImage13} alt="मेढी (medhi)" />
              <p>मेढी (medhi)</p>
            </div>
            <div className="name-item" onClick={() => handleClick('तिपड (tipad)', 'Description about तिपड :- The Tipad is a unique architectural feature in traditional Marwari homes, representing the highest and often most secluded terrace of the house. Situated at the top of the building, the Tipad is usually accessible only by indirect means, such as through a smaller staircase or ladder, making it a space that is both private and intimate. Its elevated position, away from the hustle and bustle of daily household activities, offers a tranquil retreat for those seeking quiet moments of reflection, relaxation, or solitude.One of the most captivating features of the Tipad is its panoramic views of the surrounding landscape. Being the highest point of the house, it often provides an unobstructed vista of the area, allowing residents to take in the beauty of the natural or urban surroundings. Whether it’s the sight of lush fields, nearby hills, or the rooftops of neighboring houses, the Tipad offers a vantage point that enhances the connection between the home and its environment. During early mornings or late evenings, the Tipad becomes an ideal spot to witness breathtaking sunrises or sunsets, offering a serene and peaceful atmosphere that feels worlds away from the activity below.')}>
              <img src={nameImage14} alt="तिपड (tipad)" />
              <p>तिपड (tipad)</p>
            </div>
            <div className="name-item" onClick={() => handleClick('कोठोरियो (kothariyo)', 'Description about कोठोरियो :- The Kothariyo is a small yet vital storage area found in traditional Marwari homes, located on the side of the terrace. This dedicated space is specifically designed for storing perishable food items like milk, curd, butter, and other dairy products overnight, ensuring they are kept at an optimal temperature in the cool night air while being shielded from direct sunlight during the day. The Kothariyo plays a key role in preserving food in a natural, energy-efficient manner, especially in regions where refrigeration may have been limited or unavailable in the past.Traditionally, in hot and arid climates such as Rajasthan, where Marwari culture is prevalent, maintaining the freshness of perishable items posed a challenge, particularly during the scorching summer months. The Kothariyo was developed as an ingenious solution to this problem. By situating the Kothariyo on the terrace, exposed to the cool night breeze, it allows the dairy products to be kept at a lower temperature, effectively slowing the process of spoilage. The structure of the Kothariyo ensures that the perishables are ventilated, yet protected from insects, dust, or animals, creating a natural cooling environment.')}>
              <img src={nameImage15} alt="कोठोरियो" />
              <p>कोठोरियो (kothariyo)</p>
            </div>
          </div>

          <div className="name-column">
            <div className="name-item" onClick={() => handleClick('गुन्जार (gunjar)', 'Description about गुन्जार :- The Gunjar is a traditional basement space found in many Marwari homes, playing a vital role in the household’s storage and preservation practices. Situated at the front or back of the house, the Gunjar is characterized by its cool, dark environment, making it an ideal space for storing items that need protection from heat, light, and other environmental factors. In the hot, arid climates of regions like Rajasthan, where Marwari culture is prevalent, the Gunjar is an essential feature that helps families manage their resources efficiently.The primary function of the Gunjar is to provide a cool, insulated space that naturally regulates temperature. Given that the temperature above ground can become quite extreme, especially during the summer months, the Gunjar serves as a naturally cooler area that can be used to store various items without the need for artificial cooling. The basement’s depth below the ground helps maintain a stable, cooler temperature, which is critical for preserving food, valuable items, and other household goods. The natural insulation provided by the earth surrounding the Gunjar ensures that it remains cool even during the hottest times of the year.')}>
              <img src={nameImage16} alt="गुन्जार (gunjar)" />
              <p>गुन्जार (gunjar)</p>
            </div>
            <div className="name-item" onClick={() => handleClick('भखारी (bhkhari)', 'Description about भखारी :- The Bhkhari is a small, dark, and often hidden storage space within traditional Marwari homes, typically located under the stairs or in another tucked-away corner of the house. Despite its compact and inconspicuous nature, the Bhkhari plays a crucial role in household organization by providing a dedicated area for storing items that are not in regular use, such as seasonal items, unused goods, or objects that may only be needed during specific times of the year. It exemplifies the efficient use of space that is characteristic of traditional home design, where every nook and cranny is utilized to its full potential.The primary function of the Bhkhari is to serve as a storage area for items that are not needed on a daily basis but must be kept in the home for occasional use. This can include a variety of household items, such as seasonal clothes (like woolen garments for winter), blankets, extra bedding, kitchen utensils used only during festivals or special occasions, and decorative items that are only brought out during certain times of the year. The small size of the Bhkhari makes it ideal for storing such items, as it allows the family to keep them out of sight while still maintaining easy access when needed.')}>
              <img src={nameImage17} alt="भखारी (bhkhari)" />
              <p>भखारी (bhkhari)</p>
            </div>
            <div className="name-item" onClick={() => handleClick('तिलभवरो (tilbhavro)', 'Description about तिलभवरो :- The Tilbhavro is a unique and ingeniously designed storage feature found in traditional Marwari homes, often located within the floor of the basement or other concealed areas of the house. This hidden compartment is specifically intended for storing valuable items, grains, or other important household goods that require a high level of security and protection. The Tilbhavro is typically well-concealed and strategically placed, making it an essential part of the household’s storage and security system, especially in regions where safeguarding possessions against theft or environmental threats was a priority.One of the primary functions of the Tilbhavro is to offer a safe and secure storage space for valuable possessions. Items such as family heirlooms, jewelry, important documents, or even currency were traditionally stored in the Tilbhavro, where they were protected from theft, fire, or other risks. Because the Tilbhavro is hidden within the floor and often blends seamlessly with its surroundings, it is difficult for outsiders or even household visitors to detect, making it an ideal space for safeguarding precious goods.')}>
              <img src={nameImage18} alt="तिलभवरो (tilbhavro)" />
              <p>तिलभवरो (tilbhavro)</p>
            </div>
            <div className="name-item" onClick={() => handleClick('पलिंडो (palindo)', 'Description about पलिंडो :- The Palindo is a traditional and practical feature found in many Marwari homes, serving as a designated area for storing drinking water. Located strategically outside the kitchen and near the stairs leading to the terrace, the Palindo provides easy and convenient access to fresh drinking water for all household members. Its position ensures that water is always within reach during meal preparation and other daily activities while keeping it cool and protected from contaminants. The Palindo reflects the thoughtful planning and resourcefulness of traditional home design, where essential functions like water storage were given dedicated spaces to ensure efficiency and hygiene.One of the most important aspects of the Palindo is its role in keeping the water supply cool and fresh. In the past, before modern refrigeration and plumbing systems, water was often stored in earthenware pots or metal containers that were naturally insulated, allowing the water to remain cool even during the hot summer months. The Palindo was typically shaded or located in a well-ventilated area to prevent the water from being exposed to direct sunlight, which could cause it to warm up or become stale. By positioning the Palindo outside the kitchen, the household could ensure that drinking water was easily accessible during cooking or mealtimes without interfering with the kitchen’s heat or activities.')}>
              <img src={nameImage19} alt="पलिंडो (palindo)" />
              <p>पलिंडो (palindo)</p>
            </div>
            <div className="name-item" onClick={() => handleClick('तारथ (taarth)', 'Description about तारथ :- The Taarth is the traditional toilet room found in many Marwari homes, serving an essential function in maintaining hygiene, cleanliness, and privacy within the household. In traditional homes, the Taarth was usually designed to be separate from the main living areas, often located in a more isolated part of the house, such as near the courtyard or at the back of the property. This separation was not only a practical measure to maintain cleanliness but also aligned with cultural preferences for keeping toilet facilities away from the spaces where the family lived, cooked, and entertained.One of the defining features of the Taarth is its emphasis on hygiene and sanitation. In many traditional homes, access to modern plumbing systems was either limited or non-existent, which meant that the Taarth had to be carefully designed to ensure cleanliness while minimizing the spread of germs or odors. As such, the Taarth was often a simple, utilitarian space with a focus on functionality. It would typically consist of a squat toilet or a simple pit latrine, which was easier to clean and maintain in the absence of running water.')}>
              <img src={nameImage20} alt="तारथ (taarth)" />
              <p>तारथ (taarth)</p>
            </div>
          </div>

          <div className="name-column">
            <div className="name-item" onClick={() => handleClick('नावनघर (nawanghar)', 'Description about नावनघर :- The Nawanghar is the traditional bathroom in Marwari homes, serving as a dedicated space for bathing and personal hygiene. While simple in design, the Nawanghar is essential to maintaining daily cleanliness and comfort for household members. Its primary focus is functionality and ease of use, ensuring that the family’s bathing needs are met in a hygienic and practical manner. Located strategically within the home, usually away from the main living and cooking areas, the Nawanghar embodies both cultural values and practical necessities in ensuring personal and household cleanliness.The Nawanghar is designed to facilitate the daily routines of bathing and grooming while maintaining the hygiene standards that are culturally significant in Indian households. In traditional Marwari homes, where water was often manually fetched from wells or other local sources, the Nawanghar would typically consist of a simple area equipped with a large water storage container or tank, often made of clay or metal. Buckets or pitchers would be used to pour water over the body for bathing, ensuring minimal water usage while still maintaining cleanliness. This water-conserving practice reflects the resourcefulness of traditional homes, especially in arid regions where water conservation is crucial.')}>
              <img src={nameImage21} alt="नावनघर (nawanghar)" />
              <p>नावनघर (nawanghar)</p>
            </div>
            <div className="name-item" onClick={() => handleClick('शाल (Shaal)', 'Description about शाल (Shaal) :- The Shaal serves as the warm embrace of the home, a welcoming space that immediately greets you with a sense of openness and tranquility. As the very first area encountered upon entering, the Shaal sets the tone for the entire household. Designed as a semi-open space, it often features expansive windows or arches that allow for natural light and fresh air to flow through, creating an inviting atmosphere that blurs the line between the outdoors and the interiors.Functionally, the Shaal acts as both an entry foyer and an informal gathering space. It is the spot where guests are received and family members gather to converse or take a moment of pause before continuing further into the home. In many traditional homes, the Shaal is thoughtfully designed with high ceilings and well-placed windows that keep the area bright and airy throughout the day. This architectural openness not only makes the space feel expansive but also enhances the feeling of warmth and hospitality.Beyond its functional role, the Shaal holds cultural significance. In Marwari homes, where hospitality is deeply ingrained in the family ethos, the Shaal becomes a symbolic representation of openness and generosity. It is here that visitors are welcomed with a smile, where they are offered water or refreshments, and where the first conversations take place. The Shaal is often adorned with traditional furniture, comfortable seating, or even decorative plants, all of which further enhance its role as a space of comfort and relaxation.')}>
              <img src={nameImage22} alt="शाल (Shaal)" />
              <p>शाल (Shaal)</p>
            </div>

            <div className="name-item" onClick={() => handleClick('बाडो (bhado)', 'Description about बाडो :- The Bhado refers to the garden or open space surrounding traditional Marwari homes, serving as an essential area for outdoor activities, gardening, and enjoying the natural environment. This space adds an element of greenery and tranquility to the home, offering a balance between indoor living and the natural world outside. The Bhado is more than just an open yard; it is a multifunctional area that enhances the quality of life for the family by providing a place for relaxation, practical activities, and even social gatherings, all while fostering a deeper connection with nature.One of the primary functions of the Bhado is its role as a garden. In many traditional homes, families use this open space to grow plants, flowers, vegetables, or even herbs that are used in daily cooking. The Bhado serves as a place where the family can cultivate a variety of greenery, contributing not only to the aesthetic beauty of the home but also to its self-sufficiency. In areas where land is limited or water is scarce, the Bhado becomes a carefully managed space, where families plant drought-resistant species or crops that require minimal water. The presence of greenery in the Bhado creates a sense of freshness, cooling the air around the house and making the home feel more inviting and comfortable.')}>
              <img src={nameImage22} alt="बाडो (bhado)" />
              <p>बाडो (bhado)</p>
            </div>

          
          </div>
        </div>

        {/* What This Place Offers Section */}
        <h1 className="RoomText">What This Place Offers</h1>
      </div>

      {/* Popup Modal for Name List */}
      {popupContent && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-button" onClick={handleClosePopup}>
              &times;
            </button>
            <h2>{popupContent.name}</h2>
            <p>{popupContent.description}</p>
          </div>
        </div>
      )}

      {/* Features Section */}
      <div className="features-container">
        <div className="features-column">
          <div className="feature-item">
            <FaLock className="icon" /> <span>Lock on bedroom door</span>
          </div>
          <div className="feature-item">
            <FaUtensils className="icon" /> <span>Kitchen</span>
          </div>
          <div className="feature-item">
            <FaWifi className="icon" /> <span>Wifi</span>
          </div>
          <div className="feature-item">
            <FaLaptop className="icon" /> <span>Dedicated workspace</span>
          </div>
          <div className="feature-item">
            <FaFireExtinguisher className="icon" /> <span className="strikethrough-text">Carbon monoxide alarm</span>
          </div>
        </div>
        <div className="features-column">
          <div className="feature-item">
            <FaPaw className="icon" /> <span>Pets allowed</span>
          </div>
          <div className="feature-item">
            <FaTv className="icon" /> <span>TV</span>
          </div>
          <div className="feature-item">
            <FaParking className="icon" /> <span>Free parking on premises</span>
          </div>
          <div className="feature-item">
            <FaSnowflake className="icon" /> <span>Air conditioning</span>
          </div>
          <div className="feature-item">
            <FaBell className="icon" /> <span className="strikethrough-text">Smoke alarm</span>
          </div>
        </div>
      </div>

      {/* Footer Component */}
      <Footer />
    </div>
  );
}

export default RoomSuit;
