/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import './HeroSlider.css';

import image from '../../assets/LandingImg/LandingKothi.jpeg'
import imagelogo from '../../assets/KOTHI/TheArtKothi.png'
const HeroSlider = () => {
  return (
    <div className="image-sectionHERO">
      <a href="/artkothipage">   
      <img
        src={imagelogo}
        alt="Centered Image" 
        className="KOTHILOGO"
      />
       </a>
      <br></br>
      <h1 className='ARTTEXT'>TheArtKothi</h1>
      <p className="content-text">The beautiful region of Haryana, particularly the TheArtKothi area, is one of the best places to visit. It offers vast open spaces, perfect for making your day special, and allows you to experience nature at its finest.</p>
      <img
        src={image}
        alt="Centered Image" 
        className="center-image"
      />
      <p className="content-text">TheArtKothi is a serene and peaceful spot in Haryana, offering a perfect blend of comfort and traditional charm. With its spacious bedrooms, each thoughtfully designed with an old-world aesthetic, the place exudes a sense of timeless beauty. The architecture and decor, reminiscent of a bygone era, bring a refreshing touch of nostalgia while maintaining modern comforts. Whether you're looking to unwind in the quiet of nature or immerse yourself in the tranquil surroundings, TheArtKothi promises a unique experience that blends heritage with relaxation, making it an ideal retreat for those seeking peace and rejuvenation.</p>
    </div>
  );
};

export default HeroSlider;
