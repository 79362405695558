/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

import imagelogo from "../../assets/KOTHI/TheArtKothi.png";

import NavigationBar from "../../Component/NavigationBar/NavigationBar";
import Footer from "../../Component/Footer/Footer";
import './HotelPolicies.css'

function HotelPolicies() {
  return (
    <div >
      <NavigationBar />
      <div className="hotel-policies-page-container"> 
      <div className="hotel-logo-section">
        <a href="/">
          <img src={imagelogo} alt="Hotel Logo" className="hotel-main-logo" />
        </a>
        <h1 className="hotel-main-title">Hotel Policies</h1>
      </div>

      <div className="hotel-policies-content-wrapper">
        <div className="hotel-policies-content-section">
          <h2 className="hotel-policies-heading">Room in a bungalow</h2>
          <p className="hotel-policies-text">
            In a grove of red flowers and blooming jasmine, we invite you to slip into the calm waters of our swimming pool. Complete with arched pool house, tented canopies, and a charming pavilion bar, the palm-shaded swimming pool is an oasis of earthly delight.
          </p>

          <h2 className="hotel-policies-heading">Shared common spaces</h2>
          <p className="hotel-policies-text">
            A tranquil room of one’s own with windows onto the garden, the Eremo is the ideal place for meditation, yoga, or a good workout. Designed to inspire focus, this peaceful retreat is open to all of our guests with meditation cushions, yoga mats, and free weights available.
          </p>

          <h2 className="hotel-policies-heading">Private attached bathroom</h2>
          <p className="hotel-policies-text">
            Drawing on ancient wisdom &amp; sacred knowledge, each of our signature Spa Rituals is intended as a re-awakening of the Spirit, in a journey towards the rediscovery of the Body. Our rituals encourage the internal manifestation of health, beauty, and holistic well-being.
          </p>

          <h2 className="hotel-policies-heading">Furry friends welcome</h2>
          <p className="hotel-policies-text">
            At ArtOfKohti secret gardens of perfumed jasmine &amp; luxuriant rose, where delicate pomegranate, sweet hibiscus &amp; pink laurels grow. Fountains splash &amp; fabulous formal parterre flower, while shaded verandahs open on to verdant sprays of tropical color.
          </p>
        </div>
      </div>
      </div>
      <Footer />
    </div>
  );
}

export default HotelPolicies;
