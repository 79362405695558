import React from "react"; // Import React for JSX support
// import NavigationBar from '../Component/NavigationBar/NavigationBar'; // Commented out, as NavigationBar is not currently used
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import Router, Routes, and Route components from 'react-router-dom'

import HomeLandingList from "../HomeList/HomeLandingList"; // Import the HomeLandingList component for the home page
import RoomSuit from "../Pages/Rooms&Suit/RoomSuit"; // Import the RoomSuit component
import Gallery from "../Pages/Gallery/Gallery"; // Import the Gallery component
import CafeeKothi from "../Pages/CafeeKothi/CafeeKothi"; // Import the CafeeKothi component
import Press from "../Pages/Press/Press"; // Import the Press component
import HotelPolicies from "../Pages/HotelPolicies/HotelPolicies"; // Import the HotelPolicies component
import Contact from "../Pages/Contact/Contact"; // Import the Contact component
import ArtKothiPage from "../Pages/ArtKothiPage/ArtKothiPage";

function RoutingPage() {
  // Define the RoutingPage functional component
  return (
    <div>
      {" "}
      {/* Root container for the component */}
      <Router>
        {" "}
        {/* The Router component to wrap the application */}
        <Routes>
          {" "}
          {/* The Routes component defines the different routes within the app */}
          <Route path="/" element={<ArtKothiPage />} />{" "}
          {/* Route for the home page ("/") with HomeLandingList component */}
          <Route path="/RoomSuit" element={<RoomSuit />} />{" "}
          {/* Route for "/RoomSuit" with RoomSuit component */}
          <Route path="/gallery" element={<Gallery />} />{" "}
          {/* Route for "/gallery" with Gallery component */}
          <Route path="/cafekothi" element={<CafeeKothi />} />{" "}
          {/* Route for "/cafekothi" with CafeeKothi component */}
          <Route path="/press" element={<Press />} />{" "}
          {/* Route for "/press" with Press component */}
          <Route path="/hotelpolicies" element={<HotelPolicies />} />{" "}
          {/* Route for "/hotelpolicies" with HotelPolicies component */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/homepage" element={<HomeLandingList />} />{" "}
          {/* Route for "/contact" with Contact component */}
        </Routes>{" "}
        {/* End of Routes block */}
      </Router>{" "}
      {/* End of Router block */}
    </div>
  );
}

export default RoutingPage; // Export the RoutingPage component for use in other parts of the app
