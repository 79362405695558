
const menuItems = [
    {
      category: "TAKE ME HOME (Meal Boxes)",
      items: [
        { name: "NASHTA BOX", description: "Mini Samosa. Dal Pakwaan. Tadka Maggi. Chotu Idli. Muska Bun. Lassi." },
        { name: "BREAKFAST BOX", description: "Mini Samosa. Hummus Sandwich. Chotu Rajma Pakwan. Firangi Maggi. Wheat Jaggery Cake. Cold Coffee." },
        { name: "CONTINENTAL BOX", description: "Quinoa Salad. Lavash Platter. Firangi Maggi. Pink Pasta. Wheat Jaggery Cake. Masala Shikanji." },
        { name: "CHAAT PARTY", description: "Sevpuri. Bhelpuri. Dahi Idli. Pav Bhaji. Wheat Jaggery Cake. Masala Shikanji." },
        { name: "MEZZE PLATTER", description: "Quinoa Salad. Lavash. Falafel Balls. Pita Bread. Hummus. Lemon Mint Iced Tea." },
        { name: "FAMILY PLATTER", description: "Mini Samosa. Sauteed Veg. Dal Pakwaan. Chotu Idli. Tadka Maggi. Firangi Maggi. Muska Bun Platter. Ginger Tea Pot." }
      ]
    },
    {
      category: "BAKERY & DESSERTS (Eggless Wonders)",
      items: [
        { name: "WHEAT JAGGERY CAKE", price: "₹420/₹450", description: "-80" },
        { name: "CHOTU CREAM ROLLS (सेल्फी योग्य आईटम ) (Margarine free)" },
        { name: "CHEESECAKE", price: "₹140" },
        { name: "DARK CHOCOLATE MOUSSE", price: "₹150" },
        { name: "WALNUT BROWNIE (WITH ICE-CREAM)", price: "₹240" },
        { name: "PANCAKES (Served with Honey, Maple Syrup & Ice-cream)" },
        { name: "PLAIN PANCAKE (मालपुर का भाई)", price: "₹240" },
        { name: "NUTELLA PANCAKE (मालपुर की बहन)", price: "₹330" }
      ]
    },
    {
      category: "AATA PIZZA 9\" (Italian Pizza, Indian afrit: 100% WHOLE-WHEAT BASE)",
      items: [
        { name: "OTC", description: "Onion. Tomato. Capsicum.", price: "₹420" },
        { name: "MARGHERITA", description: "Basil. Sundried Tomato.", price: "₹450" },
        { name: "DESH VIDESH", description: "Broccoli. Mushrooms. Tomato. Jalapeno. Sundried Tomato.", price: "₹480" }
      ]
    },
    {
      category: "Light-Meals (कम खाओं, गम खाओं, रम जाओं !) (12:30 PM onwards)",
      items: [
        { name: "IMAANDAAR KHICHDI*", description: "Rice. Dal. Ghee. (*Gluten Free, *Vegan Possible)", price: "₹290" },
        { name: "PLATTER (संतुलित आहार)", description: "Marinated Jackfruit. Quinoa. Broccoli. Vegetable Raita. Onion. (*Gluten Free)", price: "₹370" },
        { name: "KATHAL BIRYANI* (QUINOA)", description: "Marinated Jackfruit. Quinoa. Broccoli. (*Gluten Free)", price: "₹370" },
        { name: "PAV BHAJI", description: "3 Pav. Butter. Seasonal Vegetables. (*Gluten Free)", price: "₹390" },
        { name: "RAJMA CHAWAL*", description: "Red Kidney Beans. Rice. Garlic. Green Chilli. Onion. Coriander. Curd. (*Gluten Free, *Vegan Possible)", price: "₹390" },
        { name: "SUJI (SEMOLINA) PENNE PASTA (12:30 PM onwards)", description: "Semolina Penne. Served With Garlic Bread. Contains: Cheese & Olive" },
        { name: "SPINACH GREEN", description: "Broccoli. Zucchini. Mushroom. Garlic. Cheese.", price: "₹400" },
        { name: "WHITE PASTA", description: "Mushroom. Garlic. Onion. Sun-dried Tomatoes. Parsley.", price: "₹450" },
        { name: "PINK PASTA", description: "Tomato Base. Basil. Mushroom. Broccoli. Zucchini. Garlic. Onion. Cheese.", price: "₹480" }
      ]
    },
    {
      category: "OUTSIDE EATABLES (12:30 PM onwards) सुनो सबकी करो मन की !",
      items: [
        { name: "LEBANESE BURGER", description: "6 Mini Burgers. Falafel. Lettuce. Tomato. Cheese. Mustard. Hummus. Lavash. Salad.", price: "₹430" },
        { name: "HUMMUS PLATTER", description: "Multiple Hummus. Homemade Pita. Lavash. Feta Cheese. Salad.", price: "₹460" },
        { name: "MEZZE PLATTER (FALAFEL)", description: "Homemade Pita. Multiple Hummus. Falafel Balls. Lavash. Salad. Feta Cheese. Olives.", price: "₹490" },
        { name: "KHAKRA PIZZA (11:00 AM onwards)", description: "Khakra: Roasted Whole-Wheat Roti / Bread. Contains: Garlic & Olives." },
        { name: "COLD LEBANESE PIZZA", description: "Hummus. Tomato. Lettuce. Black Pepper. Feta Cheese.", price: "₹190" },
        { name: "BASIC KHAKRA PIZZA", description: "Onion. Tomato. Capsicum.", price: "₹200" },
        { name: "GLOBAL KHAKRA PIZZA", description: "Broccoli. Mushrooms. Onion. Tomato. Jalapeno. Sun-Dried Tomato.", price: "₹240" }
      ]
    },
    {
      category: "GARLIC BREAD & PANINI",
      items: [
        { name: "PLAIN GARLIC BREAD", price: "₹190" },
        { name: "CHEESE GARLIC BREAD", price: "₹220" },
        { name: "MASALA GARLIC BREAD", description: "Capsicum. Onion. Cheese.", price: "₹250" },
        { name: "TOC PANINI", description: "Pesto Spread. Tomato. Onion. Capsicum.", price: "₹270" },
        { name: "MUSHROOM PANINI", description: "Pesto Spread. Tossed Mushroom.", price: "₹290" },
        { name: "GRAND PANINI", description: "Tossed: Mushroom. Zucchini. Bell-Peppers. Lettuce. Feta Cheese.", price: "₹350" },
        { name: "SAUTÉED TOFU", description: "Bell-pepper. Tofu.", price: "₹300" },
        { name: "SAUTÉED MUSHROOM", description: "Tossing Mushrooms till you win.", price: "₹330" },
        { name: "SAUTÉED VEGETABLES", description: "Mushrooms. Beans. Zucchini. Broccoli. Bell-peppers.", price: "₹370" }
      ]
    },
  
    {
      category: "OTHER STREET FOOD (11:00 AM onwards) मौज लो रोज लो ना मिले तो खोज लो ।",
      items: [
        { name: "GOL GAPPE*", description: "Mint Water. Tamarind Water. Potato. Onion. Black Chickpeas. (*Vegan)", price: "₹210" },
        { name: "BHELPURI", description: "Onion. Potato. Tomato. Coriander & Sweet Chutney. Mustard. Peanuts. Lemon. Green Chilli. (*Gluten Possible)", price: "₹210" },
        { name: "SEVPURI", description: "Onion. Potato. Tomato. Sweet & Coriander Chutney. Green Chilli.", price: "₹230" },
        { name: "DAHI PURI", description: "Puchke. Sprouts. Boiled Potato. Green Chilli. Curd. Sweet Chutney. Onion. Tomato.", price: "₹230" },
        { name: "PAPDI CHAAT", description: "Boiled Potato. Onion. Curd. Mint & Tamarind Chutney. Pomegranate. Coriander. Hamkeen. (*Gluten Free)", price: "₹290" },
        { name: "DAL KE PAKODE*", description: "Gorimoth Dal. Onion. Green Chilli.", price: "₹290" },
        { name: "BHAJIZA PAKODE", description: "Potato, Onion, Green Chilli. Sesame Seeds.", price: "₹290" },
        { name: "CHOPU RAJ KAOHORI (SPROUTS PUCHKE)", description: "Chotu Raj Kachori, Sprouts. Boiled Potato. Curd, Mint & Tamarind Chutney. Dal Bhalla. Pomegranate. Beetroot.", price: "₹310" }
      ]
    }, 
    {
      category: "WHOLE-WHEAT & MULTI GRAIN GRILLED SANDWICH",
      items: [
        { name: "DESI", description: "Cooked over an open flame. Masala Potato. Tadka of Rai (Mustard). Coriander. Chilli & Neem.", price: "₹240" },
        { name: "BOMBAY MASALA (ALOO) SANDWICH", description: "Potato. Onion. Cucumber. Tomato. Butter. Mint Chutney. Cheese. मुम्बई मेरी जान।", price: "₹240" },
        { name: "BOMBAY VEGETABLE SANDWICH", price: "₹250" },
        { name: "PARDESI", description: "Electric Sandwich Griller.", price: "₹290" },
        { name: "COC", description: "Capsicum. Onion. Cheese.", price: "₹240" },
        { name: "SPINACH & PANEER (COTTAGE CHEESE)", price: "₹290" },
        { name: "MUSHROOM & CHEESE", price: "₹260" },
        { name: "WHOLE-WHEAT & MULTI GRAIN TOASTS", description: "Bread to live. Spreads to die for.", price: "₹120/₹170" },
        { name: "BUTTER TOAST", price: "₹120/₹170" },
        { name: "OPEN TOAST", price: "₹280" },
        { name: "Choice Cheese Chilly (Garlic) / Nutella", price: "₹120/₹170" }
      ]
    },
  
    {
      category: "VADA & COMPANY (PAV 70% WHOLE-WHEAT)",
      items: [
        { name: "VADA PAV", description: "Deep-fried potato dumpling (vada) placed inside a Bread Bun (pav). Garlic. Araat!", price: "₹170" },
        { name: "CHEESE VADA PAV", price: "₹190" },
        { name: "VADA PAV PLATTER", description: "6 Chotu Cheese Vada Pav. Multiple Chutney. Garlic. Bhajiya.", price: "₹350" },
        { name: "BONSAI DABELI", description: "6 Mini Dabeli. Potato. Onion. Garlic Chutney. Green Chilli. Pomegranate.", price: "₹350", note: "Half-portion for ₹190" },
        { name: "SAMOSA PLATTER", description: "Insta-fried. Chotu Samosa (Stuffed Savory Pastries). 8 Pieces. Chotu Bun.", price: "₹330", note: "Half-portion for ₹170" },
        { name: "TAPRI PUCHKE NAMKEEN SHOTS", description: "Curd. Onion. Tomato. Bundi. बहुत शानदार!", price: "₹250" },
        { name: "CORN SHOTS", description: "Corn. Cheese. Mayonnaise. My Cheesy life!", price: "₹280" }
      ]
    } , 
  
    {
      category: "नारता: जहाँ मिलेगा नारता, वही रखेंगें वास्ता !",
      items: [
        { name: "POHA", description: "Plattened Rice. Tomato. Onion. Chilli. Peanuts & Coriander. हल्का-फुल्का हिसाब-किताब (Vegan, Gluten Free)", price: "₹180" },
        { name: "KHAMANI*", description: "Behind every successful Khamani, there is Khaman. Khaman: Gram Flour Cake raised on steam & later sauteed with spices. (Served till 5 pm)", price: "₹200" },
        { name: "SUJI KHAMAN", description: "Semolina Cake Raised-On Steam. Curry Leaves, Green Chilly, Gummin & Mustard Seed Padka.", price: "₹200" },
        { name: "CHOTU IDLI PLATTER", description: "Sauteed Idli. Capsicum. Multiple Chutneys.", price: "₹250" },
        { name: "METHI PALAK THEPLA", description: "2 Pieces. Curd. Mirchi Tipore.", price: "₹250" },
        { name: "GEHU SINGH*", description: "Whole-Wheat Grain Boiled>Sauteed! Chilli. Zucchini. Broccoli. Onion. Garlic. Tomato. (*Vegan)", price: "₹280", note: "Half-portion for ₹170" },
        { name: "MOONGLET*", description: "Green Gram Flour. Cottage Cheese. Onion. Tomato. Green Chilli. (*Gluten Free)", price: "₹330", note: "Single Piece for ₹190" },
        { name: "MUSKA BUN", description: "Chai aur muska bun. Sweet Breads. Different spreads.", price: "₹120" },
        { name: "JAM BHUJIA / CHINI/ GULKAND/ CHEESE / NUTELLA MUSKA BUN", price: "₹140" },
        { name: "MUSKA BUN PLATTER", description: "Assorted Spreads.", price: "₹250" }
      ]
    },
  
    {
      category: "खाना: SPROUTS & SALADS",
      items: [
        { name: "BOWL OF SPROUTS*", description: "Moong (Green Lentil). Kala Chana (Black Chickpeas). Onion. Tomato. Black Pepper. Lemon. (Vegan, Gluten Free)", price: "₹130" },
        { name: "KALA CHANA CHAAT*", description: "Boiled Kala Chana (Black Chickpeas). Onion. Tomato. Cucumber. Green Chilli. Lemon. (Vegan, Gluten Free)", price: "₹160" },
        { name: "KALA CHANA* (Sauteed)", description: "Black Chickpeas. Mustard Seeds. Curry Leaves. Tibdik Tibdik! (Vegan, Gluten Free)", price: "₹160" },
        { name: "GOAN SALAD*", description: "Broccoli. Mushroom. Zucchini. Beans. Hung Curd. Mustard & Curry Leaves Tadka. (Gluten Free)", price: "₹250" },
        { name: "DAL PAKWAAN", description: "Steamed Daal. Crispy, Thin Base. Topping: Onion. Tomato. Chilli. (Separate Garlic available)", price: "₹250", note: "Half-portion for ₹150" },
        { name: "QUINOA UPMA*", description: "Fashion cum Power Food - Quinoa. Broccoli. Peanuts. Onion. Tomato. Carrot. Green Chilli. (Vegan, Gluten Free)", price: "₹280" },
        { name: "TOFU BHURJI", description: "Proteins ka - Tofu. Onion. Tomato. Chilli. Healthy Moments ahead!", price: "₹280" }
      ]
    },
    
    {
      category: "HOUSE ICED TEAS",
      items: [
        { name: "LEMON-MINT (BREWED HERE)", description: "Igniting Leaf. Reviving Lemon. Welcoming Mint. Note: Replace sugar with natural honey.", price: "Single ₹190 / Pitcher ₹570" },
        { name: "HIBISCUS-MINT ICED TEA", description: "Cold Steeping. Brewed here. Pure Hibiscus Flower: Vitamin C. Antioxidant. 100% Decaffeinated. Mint & Ginger. Note: Replace sugar with natural honey.", price: "Single ₹190 / Pitcher ₹570" },
        { name: "BULL BULL ICED TEA", description: "Knocking Tea. Knocking Redbull. Knock-Knock!", price: "₹370 (Mug)" },
        { name: "KOMBUCHA (FERMENTED TEA)", description: "Please ask team for available flavours.", price: "₹250" }
      ]
    }, 
    {
      category: "HANDCRAFTED TEAS (Without Milk. Separate Sugar)",
      items: [
        { name: "LEMON TEA", price: "Single ₹120 / Pot For Three ₹360" },
        { name: "JASMINE PEARL", description: "Sturdy White Teas. Delicate Jasmine. Fragrance Flavor Intercourse!", price: "Single ₹120 / Pot For Three ₹360" },
        { name: "ASSAM BLACK", description: "Orthodox Long Leaves. Dark-orange Liquor. Strong.", price: "Single ₹110 / Pot For Three ₹330" },
        { name: "DARJEELING BLACK", description: "Full-bodied Cup. Musky Notes. Bold.", price: "Single ₹110 / Pot For Three ₹330" },
        { name: "EARL GREY", description: "Black Tea. Flavour: Bergamot Orange. After-taste.", price: "Single ₹120 / Pot For Three ₹360" },
        { name: "ENGLISH BREAKFAST", description: "Light Liquor. Separate Milk. Truly British.", price: "Single ₹130 / Pot For Three ₹390" },
        { name: "FILTER COFFEE (SOUTH INDIAN)", price: "₹110" },
        { name: "TAPRI COFFEE (HAND BEATEN)", price: "₹120" },
        { name: "PLUNGER COFFEE (French Press)", price: "₹130" }
      ]
    },
  
    {
      category: "LEMON TEA UNCHAI , चाय पीने से काले होते है फेयर एण्ड लवली से गोरे होते है",
      items: [
        { name: "LEMON TEA", description: "Local Lemon Tea. 100% Organic Honey. Ho Sugar. Mint. Black Pepper. Ginger. Black Salt.", price: "₹150" },
        { name: "FILTER COFFEE (SOUTH OF INDIA)", description: "Manual Coffee. Served with Milk. Separate Sugar.", price: "₹150" },
        { name: "TAPRI COFFEE (HAND BEATEN)", description: "यहाँ कॉफी हाथ से फेटी जाती है। Sugar-coffee Paste. Local Taste. Separate Sugar. Request for Separate Milk.", price: "₹190" },
        { name: "PLUNGER COFFEE (French Pressed)", price: "₹190" },
        { name: "CLASSIC COLD COFFEE", price: "₹230" },
        { name: "COLD COFFEE ICE CREAM", price: "₹250" },
        { name: "FRESH JUICES & MILK SHAKES", description: "Seasonal Juice (Cold Pressed)", price: "₹190" },
        { name: "SEASONAL SHAKE", price: "₹220" },
        { name: "STRAWBERRY SHAKE", price: "₹220" },
        { name: "DARK CHOCOLATE SHAKE", price: "₹250" },
        { name: "KOKAM SHARBAT", description: "Choice: Soda/Water", price: "Single ₹150 / Pitcher ₹450" },
        { name: "MASALA SHIKANJI (Fresh Lime)", description: "Choice: Soda/Water", price: "Single ₹180 / Pitcher ₹540" }
      ]
    },
    {
      category: "GREEN TEAS 2-8031 हरी करे जो खरी / (Without Milk. Separate Sugar)",
      items: [
        { name: "ORGANIC GREEN", description: "Truth is Bitter. Strong Too.", price: "Single ₹120 / Pot For Three ₹360" },
        { name: "KASHMIRI KAHWA", description: "Green Tea Base. Saffron. Almond. Cinnamon. Cardamom.", price: "Single ₹120 / Pot For Three ₹360" },
        { name: "CHINESE GREEN", description: "Bitter Taste. Better After-taste.", price: "Single ₹120 / Pot For Three ₹360" },
        { name: "JAPANESE GREEN", description: "Stay light. Spread Light.", price: "Single ₹130 / Pot For Three ₹390" },
        { name: "WHITE DARJEELING", description: "Tea Leaves & Buds. Strong & Light. Rational.", price: "Single ₹120 / Pot For Three ₹360" },
        { name: "VIRGIN NEEDLE", description: "Pure. Close to Life. Unconquered! Note: Replace sugar with natural honey.", price: "Single ₹140 / Pot For Three ₹420" }
      ]
    } ,
  
    {
      category: "HERBAL TEAS (TISANE) (Without Milk. Separate Sugar)",
      items: [
        { name: "BASIC HERBAL (DECAFFEINATED)", description: "Ashwagandha. Bay-leaf. Rose. Lemongrass. Mulethi. Hibiscus. Fennel. Brahmi. Mint.", price: "Single ₹120 / Pot For Three ₹360" },
        { name: "ROSE TEA", description: "Sincere Leaf. Flirty Rose.", price: "Single ₹120 / Pot For Three ₹360" },
        { name: "HIBISCUS (DECAFFEINATED)", description: "Dried China Rose. Vitamin C. Above normal Taste.", price: "Single ₹120 / Pot For Three ₹360" },
        { name: "LOUD HERB (DECAFFEINATED)", description: "Natural Peppermint. Lemongrass. Rose. Arresting!", price: "Single ₹140 / Pot For Three ₹420" },
        { name: "FLOWER N FLIRT (DECAFFEINATED)", description: "Rose. Hibiscus. Passion Flower. Rose Hips.", price: "Single ₹140 / Pot For Three ₹420" },
        { name: "ORGANIC ROOIBOS (DECAFFEINATED)", description: "Red Tea. South Africa. Full-jungleee (raw).", price: "Single ₹140 / Pot For Three ₹420" },
        { name: "CHAMOMILE (DECAFFEINATED)", description: "Strong Digestion. Sound Sleep. Clean Face. Note: Replace sugar with natural honey.", price: "Single ₹140 / Pot For Three ₹420" }
      ]
    } ,
  
    {
      category: "GREEN TEAS & WHITE TEAS (Without Milk. Separate Sugar)",
      items: [
        { name: "ORGANIC GREEN", description: "Truth is Bitter. Strong Too.", price: "Single ₹120 / Pot For Three ₹360" },
        { name: "KASHMIRI KAHWA", description: "Green Tea Base. Saffron. Almond. Cinnamon. Cardamom.", price: "Single ₹120 / Pot For Three ₹360" },
        { name: "CHINESE GREEN", description: "Bitter Taste. Better After-taste.", price: "Single ₹120 / Pot For Three ₹360" },
        { name: "JAPANESE GREEN", description: "Stay light. Spread Light.", price: "Single ₹130 / Pot For Three ₹390" },
        { name: "WHITE DARJEELING", description: "Tea Leaves & Buds. Strong & Light. Rational.", price: "Single ₹120 / Pot For Three ₹360" },
        { name: "VIRGIN NEEDLE", description: "Pure. Close to Life. Unconquered! Note: Replace sugar with natural honey.", price: "Single ₹140 / Pot For Three ₹420" }
      ]
    } ,
    {
      category: "THADIBAAZI (Milk Teas)",
      items: [
        { name: "CUTTING CHAI", description: "Milk Teas. Medium Sugar. More water. Less milk.", price: "Chhotu Pot ₹150 / Bada Pot ₹300" },
        { name: "MASALA CHAI", description: "Conversations are incomplete until: Lemongrass. Ginger. Cardamom. Black Pepper. Cinnamon.", price: "Chhotu Pot ₹180 / Bada Pot ₹360" },
        { name: "CUTTING ISHPECIAL (CARDAMOM)", description: "पानी कम. दूध ज्यादा. Milky Tea.", price: "Chhotu Pot ₹180 / Bada Pot ₹360" },
        { name: "GINGER", description: "Pure. Strong. Healing.", price: "Chhotu Pot ₹180 / Bada Pot ₹360" },
        { name: "TULSI (BASIL)", description: "Tea's Better Half- Tulsi. Being Complete!", price: "Chhotu Pot ₹180 / Bada Pot ₹360" },
        { name: "THADI NATHDWARA", description: "Mint. Lemongrass. Ginger. Janta Mints Money. We Mint Chai!", price: "Chhotu Pot ₹220 / Bada Pot ₹440" },
        { name: "THETH CHAI", description: "Saffron. Cardamom.", price: "Chhotu Pot ₹220 / Bada Pot ₹440" },
        { name: "MY CUP OF TEA", description: "Personalized Chai: Please brief our team on ingredients. Note 1: Chai in kulhad (Earthenware)? Note 2: Replace Sugar with Jaggery? Please specify it to our service team.", price: "Chhotu Pot ₹240 / Bada Pot ₹480" }
      ]
    } , 
    
  ];

  export default menuItems;