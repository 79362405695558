/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div class="container">
        <div class="footer-content">
          <ul class="footer-links">
            <li>
              <Link to="/">&copy; 2024 TheArtKothi.</Link>
            </li>
            <li>
              <Link to="/RoomSuit">Rooms & Suites</Link>
            </li>
            <li>
              <Link to="/gallery">Gallery</Link>
            </li>
            <li>
              <Link to="/cafekothi">Art Cafe</Link>
            </li>
            <li>
              <Link to="/press">Press</Link>
            </li>

            <li>
              <Link to="https://www.instagram.com/theartkothi?igsh=MWpsNHNhZjdoa3plbg%3D%3D&utm_source=qr">
                Check Availability
              </Link>
            </li>
            <li>
              <Link to="/hotelpolicies">Hotel Policies</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
          <br></br>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
