/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import NavigationBar from '../../Component/NavigationBar/NavigationBar';
import KothiSlider from '../../Component/KothiSlider/KothiSlider';
import Footer from '../../Component/Footer/Footer';
import './Press.css'; // Import CSS file for styling

// Import images directly
import Kothi18 from '../../assets/KOTHI/Kothi18.jpeg';
import Kothi19 from '../../assets/KOTHI/Kothi19.jpeg';
import Kothi20 from '../../assets/KOTHI/Kothi20.jpeg';
import Kothi21 from '../../assets/KOTHI/Kothi21.jpeg';
import Kothi22 from '../../assets/KOTHI/Kothi22.jpeg';
import Kothi23 from '../../assets/KOTHI/Kothi23.jpeg';
import Kothi24 from '../../assets/KOTHI/Kothi24.jpeg';
import Kothi25 from '../../assets/KOTHI/Kothi25.jpeg';
import Kothi26 from '../../assets/KOTHI/Kothi26.jpeg';
import imagelogo from '../../assets/KOTHI/TheArtKothi.png'

function Press() {
  return (
    <div>
      <NavigationBar />
    
      <div className="image-gallery">
      <a href="/">
      <img
        src={imagelogo}
        alt="Centered Image" 
        className="KOTHILOGO"
      />
      </a>
        <h1 className='PressText'>Press</h1>
      <p className='ROOMTEXTPARA'>With its spacious rooms and suites, thoughtfully designed to reflect old-world charm while providing modern comforts, TheArtKothi exudes a timeless elegance. The interiors are warm and inviting, featuring handcrafted furniture, vintage decor, and large windows that flood the spaces with natural light and offer breathtaking views of the surrounding greenery. The peaceful ambiance is complemented by its exceptional dining experience, where every meal is a celebration of local flavors and international culinary traditions. Guests can enjoy freshly brewed coffee in the morning, a variety of traditional and modern dishes made with locally sourced ingredients, and a drinks menu that offers everything from fresh juices to fine wines and spirits. </p>
       <br></br>
        <div className="container gallery-container">
        
          <div className="gallery-item">
            <img src={Kothi18} alt="Gallery Item 1" className="gallery-image" />
          </div>
          <div className="gallery-item">
            <img src={Kothi19} alt="Gallery Item 2" className="gallery-image" />
          </div>
          <div className="gallery-item">
            <img src={Kothi20} alt="Gallery Item 3" className="gallery-image" />
          </div>
          <div className="gallery-item">
            <img src={Kothi21} alt="Gallery Item 4" className="gallery-image" />
          </div>
          <div className="gallery-item">
            <img src={Kothi22} alt="Gallery Item 5" className="gallery-image" />
          </div>
          <div className="gallery-item">
            <img src={Kothi23} alt="Gallery Item 6" className="gallery-image" />
          </div>
          <div className="gallery-item">
            <img src={Kothi24} alt="Gallery Item 7" className="gallery-image" />
          </div>
          <div className="gallery-item">
            <img src={Kothi25} alt="Gallery Item 8" className="gallery-image" />
          </div>
          <div className="gallery-item">
            <img src={Kothi26} alt="Gallery Item 9" className="gallery-image" />
          </div>
        </div>
        <p className='ROOMTEXTPARA'>the grand indoor setting, on the terrace under the stars, or sipping a coffee while enjoying the garden view, every moment is designed to be memorable. TheArtKothi also offers quiet nooks for relaxation, common areas for conversation, and outdoor also indoor spaces where guests can reconnect with nature. It is a place where heritage meets contemporary comfort, where the beauty of the surroundings and the warmth of the interiors come together to create an unforgettable experience. Whether you're looking for a peaceful retreat, a cultural escape, or a luxurious getaway, TheArtKothi promises to make your stay truly special.</p>
      </div>
      <Footer />
    </div>
  );
}

export default Press;
