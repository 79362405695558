import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./NavigationBar.css";

import imagelogo from "../../assets/KOTHI/NewArtKothi.png"; // Replace this with the correct image path or import

const NavigationBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      {/* Hamburger Menu */}
      <div className="appBar">
        <div className="menu-icon" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>

      {/* Logo */}
      <div className="imglogo">
        <img src={imagelogo} alt="img logo" />
      </div>

      {/* Book Now Button */}
     

      {/* Navigation List */}
      <ul className={`nav-list ${isMenuOpen ? "open" : ""}`}>
        <li className="nav-item">
          <Link to="/">THE HOTEL</Link>
        </li>
        <li className="nav-item">
          <Link to="/RoomSuit">Rooms & Suites</Link>
        </li>
        <li className="nav-item">
          <Link to="/gallery">Gallery</Link>
        </li>
        <li className="nav-item">
          <Link to="/cafekothi">Art Cafe</Link>
        </li>
        <li className="nav-item">
          <Link to="/press">Press</Link>
        </li>
        <li className="nav-item">
          <Link to="https://www.instagram.com/theartkothi?igsh=MWpsNHNhZjdoa3plbg%3D%3D&utm_source=qr">
            Check Availability
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/hotelpolicies">Hotel Policies</Link>
        </li>
        <li className="nav-item">
          <Link to="/contact">Contact</Link>
        </li>
      </ul>

       {/* Book Now Button */}
       <Link
        className="booking-button d-block d-md-block"
        to="https://www.instagram.com/theartkothi?igsh=MWpsNHNhZjdoa3plbg%3D%3D&utm_source=qr"
      >
        Book Now
      </Link>

    </nav>
  );
};

export default NavigationBar;
