
import 'bootstrap/dist/css/bootstrap.min.css';
import RoutingPage from './Route/RoutingPage';
import './App.css';







function App() {
  return (
    <div className="App">
<RoutingPage/>
    </div>
  );
}

export default App;
