import React from "react";
import HeroSlider from "../Component/HeroSlider/HeroSlider";


import Footer from "../Component/Footer/Footer";

import NavigationBar from "../Component/NavigationBar/NavigationBar";

function HomeLandingList() {
  return (
    <div>
      <NavigationBar />
      <HeroSlider />
      <br></br>
   
    
      <br></br>

      <Footer />
      {/* {isPopupVisible && <PopupMessage onClose={handlePopupClose} />} */}
    </div>
  );
}

export default HomeLandingList;
