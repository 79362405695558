import React from "react";
import "./ArtKothiPage.css";
import imagelogo from "../../assets/KOTHI/TheArtKothi.png";
import { Link } from "react-router-dom";

const ArtKothiPage = () => {
  return (
    <div className="inside">
      <div className="mod_article d-flex flex-column justify-content-center align-items-center block">
        <div className="ce_hyperlink block">
          <figure className="image_container">
            <Link
              to="/homepage"
              title="Villa Palladio Jaipur"
              className="hyperlink_img"
            >
              <img
                src={imagelogo}
                alt="Villa Palladio Jaipur"
                className="img-fluid logoImg"
                itemprop="image"
              />
            </Link>
          </figure>
        </div>
        <div className="artkothipage">
          <h1 className="title mb-7">
            <Link to="/homepage" title="The Hotel" className="artkothitext">
              Welcome To The Art Kothi
              <br />
              <span></span>
            </Link>
          </h1>
          <Link
            className="btn btn-outline-light mb-1 mb-sm-7 mb-xl-2"
            to="/homepage"
            title="Enter"
          >
            Enter
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ArtKothiPage;
