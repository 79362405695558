/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import NavigationBar from '../../Component/NavigationBar/NavigationBar';
import image1 from '../../assets/KOTHI/Kothi30.jpeg';
import image2 from '../../assets/KOTHI/Kothi8.jpeg';
import image3 from '../../assets/KOTHI/Kothi19.jpeg'; // Import the new image
import image4 from '../../assets/KOTHI/KOTHIGARDEN.png'; // Import the new image
import image5 from '../../assets/KOTHI/kohtiDin.png'; // Import the new image
import image6 from '../../assets/KOTHI/Kothi23.jpeg'; 
import imagelogo from '../../assets/KOTHI/TheArtKothi.png'
import './GallerySection.css'; // Import the CSS file
import Footer from '../../Component/Footer/Footer';

function Gallery() {
  return (
    <div>
      <NavigationBar />

      <div className="room-suit-container">
      <a href="/">
      <img
        src={imagelogo}
        alt="Centered Image" 
        className="KOTHILOGO"
      />
      </a>
        <h1 className='GalleryText'>Gallery</h1>
        <p className='ROOMTEXTPARA'>Exquisite décor pieces, beautiful artworks, a view of the lush manicured garden and relaxed seating set the mood at our lounge, A wall of strikingly beautiful hand-painted art, signature latticed windows, an eclectic selection of books and comfy seating ensures an immersive experience.</p>
      
      <br></br>
        <div className="image-grid">
          <img src={image1} alt="Image 1" />
          <img src={image2} alt="Image 2" />
          <img src={image3} alt="Image 3" />
          <img src={image4} alt="Image 4" />
          <img src={image5} alt="Image 5" />
          <img src={image6} alt="Image 6" />
        </div>
        <br></br>
        <p className='ROOMTEXTPARA'>Our lush lawns, bordered by vibrant flowers and a fragrant herb garden, create a peaceful sanctuary where nature thrives. Whether you're starting your day with breakfast overlooking this serene landscape or enjoying the company of birds and squirrels, our courtyard promises a sense of calm. For a special evening, indulge in a romantic candle-lit dinner under the stars, surrounded by stunning floral arrangements. Our vegetarian, farm-to-table cuisine, crafted from fresh ingredients, offers a delicious fusion of wholesome global flavors, making every meal an unforgettable experience.</p>
        </div>
      <Footer/>

</div>
  );
}

export default Gallery;
